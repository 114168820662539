import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/Layout';

class About extends React.Component {
  render() {
    const { logo } = this.props.data;

    return (
      <Layout>
        <main className="columns about">
          <div className="column">
            <Image fluid={logo.childImageSharp.fluid} />
          </div>

          <div className="column">
            <h1>PICKYPALETTE</h1>
            <p>
              Hi! I'm Alicia Chan, an illustrator and graphic designer based in
              Singapore. I started Pickypalette in 2016 as a platform for me to
              showcase my works.
            </p>
            <p>
              Over the years, I've come to realise that my works often revolve
              around illustration and educating/impacting children. Among the
              wide range of projects that I've worked on, the ones related to
              children are the ones that I connect with the most.
            </p>
            <p>
              For me, being a designer is more than just about creating
              aesthetically pleasing works. It is about creating products that
              send a positive message to make this world a better place, even if
              that impact is minute.
            </p>
            <p>
              <a href="https://instagram.com/picky.palette">
                <i class="fab fa-lg fa-instagram" />
              </a>

              <a href="mailto:hello@pickypalette.com">
                <i class="far fa-lg fa-envelope" />
              </a>
            </p>
          </div>
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query aboutQuery {
    logo: file(name: { eq: "profile" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default About;
